<template>
  <el-dialog
    title="编辑"
    :visible="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="$emit('dialogCancel')"
    width="80%">
    <div class="components-container">
      <el-form :model="form" label-width="60px" label-position="left" :rules="rules" ref="form" >
        <el-row :getter="20">
          <el-col :span="12">
            <el-form-item style="margin-bottom: 20px;" prop="title" label="标题">
              <el-input v-model="form.title" placeholder="标题"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item style="margin-bottom: 20px;" prop="subtitle" label="副标题">
              <el-input v-model="form.subtitle" placeholder="副标题"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :getter="20">
          <el-col :span="8">
            <el-form-item label="排序" prop="sort">
              <el-input-number v-model="form.sort" :min="0" :max="10000" label="分类排序"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item style="margin-bottom: 20px;" prop="article_classification" label="分类">
              <el-select v-model="form.article_classification" placeholder="分类">
                <el-option
                  v-for="item in optionsClassification"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item style="margin-bottom: 20px;" prop="is_publish">
              <el-switch
                v-model="form.is_publish"
                :active-value="true"
                :inactive-value="false"
                active-text="发布"
                inactive-text="私有">
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item style="margin-bottom: 20px;" prop="summary" label="摘要">
          <el-input type="textarea" v-model="form.summary" :rows="5" placeholder="摘要"></el-input>
        </el-form-item>
        <el-form-item style="margin-bottom: 20px;" prop="content" label="内容">
<!--          <tinymce v-model="form.content" :height="500" />-->
<!--          <markdown-editor v-model="form.content" height="500px"/>-->
          <mavon-editor v-model="form.content" height="500px"/>
        </el-form-item>
        <el-form-item style="text-align: center">
          <el-button type="primary" @click="onSubmit">创建</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
// import Tinymce from '@/components/Tinymce'
// import MarkdownEditor from '@/components/MarkdownEditor'
import {modifyArticle} from '@/api/article.js'
import {getArticleClassfiy} from '@/api/dict'

export default {
  name: 'TinymceDemo',
  // components: { MarkdownEditor },
  props:{
    row:{
      type:Object,
      require:true,
      default:()=>{}
    }
  },
  data() {
    return {
      form: {
        title: '',
        subtitle: '',
        article_classification:'',
        sort:'',
        summary:'',
        content:'',
        is_publish:false
      },
      optionsClassification:[],
      rules:{
        title:[
          {required:true, message:'请填写标题！', trigger: 'blur'},
          { min: 1, max: 45, message: '长度在 1 到 45 个字符', trigger: 'blur' }
        ],
        article_classification:[
          {required:true, message:'请选择分类！', trigger: 'blur'}
        ],
        is_publish:[
          {required:true, message:'请选择是否发布！', trigger: 'blur'}
        ],
        sort:[
          { required: true, message: '请输入分类排序', trigger: 'blur' }
        ],
        summary:[
          { required: true, message: '请输入摘要', trigger: 'blur' },
          { min: 1, max: 100, message: '长度在 1 到 100 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.getArticleClassfiyDict()
  },
  watch:{
    'form.sort'(n){
      if (n===undefined||n===''){
        this.$set(this.form, 'sort', 0)
      }
    }
  },
  mounted() {
    this.$set(this.form, 'title', this.row.title)
    this.$set(this.form, 'subtitle', this.row.subtitle)
    this.$set(this.form, 'article_classification', this.row.article_classification)
    this.$set(this.form, 'summary', this.row.summary)
    this.$set(this.form, 'id', this.row.id)
    this.$set(this.form, 'content', this.row.content)
    this.$set(this.form, 'is_publish', this.row.is_publish)
    this.$set(this.form, 'sort', this.row.sort)
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          modifyArticle(this.form).then(res=>{
            if (res.code!==1){
              this.$message.error(res.message)
            }else{
              this.$message({
                type:'success',
                message:'更新成功'
              })
              this.$emit('dialogCancel')
              this.$emit('dialogEdit')
            }
          })
            .catch(err=>{
              console.log(err)
            })
        } else {
          return false
        }
      })
    },
    getArticleClassfiyDict(){
      getArticleClassfiy().then(res=>{
        if (res.success){
          this.optionsClassification=res.data
        } else {
          console.log('获取字典失败')
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>
.editor-content{
  margin: 20px 100px;
}
.components-container {
  margin: 30px;
}
</style>

