<template>
  <div class="home">
      欢迎来到前端世界
  </div>
</template>

<script>
export default {
  name: 'Home'

}
</script>
