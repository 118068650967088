<template>
  <el-dialog
    title="编辑"
    :visible="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="$emit('dialogCancel')"
    width="80%">
    <div class="components-container">
      <el-form :model="form" label-width="60px" label-position="left" :rules="rules" ref="form" >
        <el-form-item style="margin-bottom: 20px;" prop="name" label="名称">
          <el-input v-model="form.name" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item style="margin-bottom: 20px;" prop="link" label="链接">
          <el-input v-model="form.link" placeholder="请输入链接"></el-input>
        </el-form-item>
        <el-form-item style="margin-bottom: 20px;" prop="icon" label="图标">
          <el-input v-model="form.icon" placeholder="请输入图标地址"></el-input>
        </el-form-item>
        <el-form-item style="margin-bottom: 20px;" prop="icon" label="描述">
          <el-input v-model="form.description" type="textarea" :rows="2" placeholder="请输入描述"></el-input>
        </el-form-item>
        <el-row :getter="20">
          <el-col :span="8">
            <el-form-item label="排序" prop="sort">
              <el-input-number v-model="form.sort" :min="0" :max="10000" label="分类排序"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item style="margin-bottom: 20px;" prop="collection_classification" label="分类">
              <el-select v-model="form.collection_classification" placeholder="分类">
                <el-option
                  v-for="item in optionsClassification"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item style="margin-bottom: 20px;" prop="is_publish">
              <el-switch
                v-model="form.is_publish"
                :active-value="true"
                :inactive-value="false"
                active-text="发布"
                inactive-text="私有">
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item style="text-align: center">
          <el-button type="primary" @click="onSubmit">创建</el-button>
        </el-form-item>
      </el-form>

      <div class="editor-content" v-html="form.content" />
    </div>
  </el-dialog>
</template>

<script>
import {modifyCollection} from '@/api/collections.js'
import {getCollectionsClassfiy} from '@/api/dict'

export default {
  name: 'TinymceDemo',
  props:{
    row:{
      type:Object,
      require:true,
      default:()=>{}
    }
  },
  data() {
    return {
      form: {
        name: '',
        link: '',
        icon:'',
        description:'',
        collection_classification:'',
        sort:'',
        is_publish:false
      },
      optionsClassification:[],
      rules:{
        name:[
          {required:true, message:'请填写标题！', trigger: 'blur'},
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        collection_classification:[
          {required:true, message:'请选择分类！', trigger: 'blur'}
        ],
        is_publish:[
          {required:true, message:'请选择是否发布！', trigger: 'blur'}
        ],
        sort:[
          { required: true, message: '请输入分类排序', trigger: 'blur' }
        ],
        link:[
          { required: true, message: '请输入链接', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.getCollectionsClassfiyDict()
  },
  watch:{
    'form.sort'(n){
      if (n===undefined||n===''){
        this.$set(this.form, 'sort', 0)
      }
    }
  },
  mounted() {
    this.$set(this.form, 'name', this.row.name)
    this.$set(this.form, 'link', this.row.link)
    this.$set(this.form, 'icon', this.row.icon)
    this.$set(this.form, 'description', this.row.description)
    this.$set(this.form, 'collection_classification', this.row.collection_classification)
    this.$set(this.form, 'id', this.row.id)
    this.$set(this.form, 'is_publish', this.row.is_publish)
    this.$set(this.form, 'sort', this.row.sort)
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          modifyCollection(this.form).then(res=>{
            if (res.code!==1){
              this.$message.error(res.message)
            }else{
              this.$message({
                type:'success',
                message:'更新成功'
              })
              this.$emit('dialogCancel')
              this.$emit('dialogEdit')
            }
          })
            .catch(err=>{
              console.log(err)
            })
        } else {
          return false
        }
      })
    },
    getCollectionsClassfiyDict(){
      getCollectionsClassfiy().then(res=>{
        if (res.success){
          this.optionsClassification=res.data
        } else {
          console.log('获取字典失败')
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>
.editor-content{
  margin: 20px 100px;
}
.components-container {
  margin: 30px;
}
</style>

