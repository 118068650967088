import request from '../utils/request'


//获取收藏项列表
export const getCollectionsList=()=>{
  return request.get('/admin/getCollectionsList')
}
//创建收藏项
export const createCollections=(data)=>{
  return request.post('/admin/createCollections', data)
}
//修改收藏项
export const modifyCollections=(data)=>{
  return request.post('/admin/modifyCollections', data)
}
//删除收藏项
export const deleteCollections=(data)=>{
  return request.post('/admin/deleteCollections', data)
}



//获取收藏列表
export const getCollectionList=()=>{
  return request.get('/admin/getCollectionList')
}
//创建收藏
export const createCollection=(data)=>{
  return request.post('/admin/createCollection', data)
}
//修改收藏
export const modifyCollection=(data)=>{
  return request.post('/admin/modifyCollection', data)
}
//删除收藏
export const deleteCollection=(data)=>{
  return request.post('/admin/deleteCollection', data)
}
