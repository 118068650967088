import request from '../utils/request'


//获取文章分类列表
export const getArticleClassfiyList=()=>{
  return request.get('/admin/getArticleClassfiyList')
}
//创建文章分类项
export const createArticleClassfiy=(data)=>{
  return request.post('/admin/createArticleClassfiy', data)
}
//修改文章分类项
export const modifyArticleClassfiy=(data)=>{
  return request.post('/admin/modifyArticleClassfiy', data)
}
//删除文章分类项
export const deleteArticleClassfiy=(data)=>{
  return request.post('/admin/deleteArticleClassfiy', data)
}

//获取文章列表
export const getArticleList=()=>{
  return request.get('/admin/getArticleList')
}
//创建文章
export const createArticle=(data)=>{
  return request.post('/admin/createArticle', data)
}
//修改文章
export const modifyArticle=(data)=>{
  return request.post('/admin/modifyArticle', data)
}
//删除文章
export const deleteArticle=(data)=>{
  return request.post('/admin/deleteArticle', data)
}
